import styled, { css } from 'styled-components'

export const Heading2 = css`
  margin-bottom: 1rem;
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.2;
`;

export const GroupHeading = styled.h1`
  margin-bottom: 1rem;
  font-family: Roboto;
  font-weight: 500;
  font-size: 2.5rem;
  line-height: 1.2;
`

export const HeadlineLink = css`
  text-decoration: none;
  color: black;
  transition: 0.1s;

  &:hover, &:focus {
    color: #03a9f4;
  }
`;
